import "core-js/stable";
import "regenerator-runtime/runtime"
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start()
require("@rails/activestorage").start()

$('input[name="boulder[wall_id]"], input[name="route[wall_id]"]').click(function(){
  const element = document.querySelector('.image-editor');
  const rect = element.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

  window.scroll({
    top: rect.top + rect.height / 2 - viewHeight / 2,
    behavior: 'smooth'
  });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')
window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2/dist/sweetalert2.js');

import main from '../src/js/main.js'
import '../site/badge-alert.js'
import '../site/qrcode.js'
import '../site/image-editor.js'

import '../site/jungleboard-image-editor.js'
import '../site/modal.js'
import '../site/random-problem-name.js'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all'
import '../src/sass/main.scss'
import 'autotrack'

FontAwesome.config.autoReplaceSvg = "nest"
import Turbolinks from "turbolinks"
Turbolinks.start()

import CookiesEuBanner from "cookies-eu-banner/dist/cookies-eu-banner"
new CookiesEuBanner(function () {
  if(process.env.NODE_ENV == 'production') {
    ga('create', 'UA-156436151-1', 'auto');

    // Only require the plugins you've imported above.
    ga('require', 'eventTracker');
    ga('require', 'outboundLinkTracker');
    ga('require', 'urlChangeTracker');
    ga('require', 'maxScrollTracker');

    ga('send', 'pageview');

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  }
});

import 'sweetalert2/dist/sweetalert2.css'
import "lightbox2/dist/css/lightbox"
import lightbox from "lightbox2/dist/js/lightbox"
lightbox.option({
  'resizeDuration': 200,
  'wrapAround': true,
  'alwaysShowNavOnTouchDevices': true
})

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }

    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    } // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      console.log("Permission to receive notifications has been granted");
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("Permission to receive notifications has been granted");
        }
      });
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
